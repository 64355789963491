import { useScroll } from '@vueuse/core'
import type { NuxtError } from 'nuxt/app'
import type { IAppErrorData } from '~~/types/error'
import type {
  IPagination,
  IRecordTarget,
  IRecordResponse,
  IRecordResponseList,
} from '~~/types/record'
import { RECORD_LIST_ITEMS_PER_PAGE } from '~~/types/record'
import type { TStyleDesign } from '~~/types/style'

interface IRecordOptions {
  endpoint?: string
  key?: string
}

interface IRecordListOptions {
  refreshOnLoad: boolean
  paginateByScroll: boolean
  scrollOffset: number
  limit: number | null
  key?: string
}

const recordListOptionsDefaults: IRecordListOptions = {
  refreshOnLoad: true,
  paginateByScroll: true,
  scrollOffset: 100,
  limit: RECORD_LIST_ITEMS_PER_PAGE,
}

export const useRecordBase = <T>(entity: string) => {
  const { t } = useI18n()

  const target = ref<IRecordTarget<T>>({
    item: undefined,
    action: undefined,
    pending: undefined,
  }) as Ref<IRecordTarget<T>>

  const setTarget = (item?: T, action?: string): void => {
    target.value = { item, action, pending: false }
  }
  const create = (): void => {
    navigateTo(`/${entity}/create`)
  }
  const deleteConfirm = async (
    id: number | string,
    callback?: () => unknown | Promise<unknown>,
    stayPending?: boolean,
  ): Promise<void> => {
    try {
      setTarget()
      target.value.pending = true

      await $request(`/api/${entity}/${id}`, { method: 'DELETE' })

      useNotify('success', t('record.message.delete-success'))

      callback?.()
    } catch (e) {
      const error = e as NuxtError<IAppErrorData>

      if (error?.data?.message) {
        useNotify('error', error.data.message)
      }

      return Promise.reject(e)
    } finally {
      if (!stayPending) {
        target.value.pending = false
      }
    }
  }

  return {
    target,
    create,
    setTarget,
    deleteConfirm,
  }
}

export const useRecord = async <T>(
  entity: string,
  id?: string,
  options?: Partial<IRecordOptions>,
) => {
  const { t } = useI18n()
  const route = useRoute()

  const { target, setTarget, deleteConfirm } = useRecordBase<T>(entity)

  const { data, status, error, refresh } = await useLazyAsyncData<
    IRecordResponse<T>
  >(`${entity}-${id}-${route.fullPath}`, () =>
    $request<IRecordResponse<T>>(
      options?.endpoint ? `/api/${options.endpoint}` : `/api/${entity}/${id}`,
    ),
  )
  const initializing = computed<boolean>(
    () => !status.value || (pending.value && !data.value),
  )
  const pending = computed<boolean>(
    () => status.value === 'pending' || !!target.value?.pending,
  )

  watch(error, (value) => {
    if (value) {
      const e = value as NuxtError<IAppErrorData>

      if (e.statusCode === 404) {
        useNotify('error', t('error.message.not-found'))
      } else {
        useNotify('error', t('error.message.error-occurred'))
      }

      navigateTo(`/${entity}`)
    }
  })

  return {
    data,
    pending,
    error,
    initializing,
    target,
    setTarget,
    refresh,
    deleteConfirm,
  }
}

export const useRecordList = async <T>(
  entity: string,
  options: Partial<IRecordListOptions> = recordListOptionsDefaults,
  filters?:
    | MaybeRef<Record<string, unknown>>
    | ComputedRef<Record<string, unknown>>,
) => {
  const route = useRoute()

  const recordListOptions: IRecordListOptions = {
    ...recordListOptionsDefaults,
    ...options,
  }

  const pageCurrent = ref<number>(1)

  const { target, setTarget, create, deleteConfirm } = useRecordBase<T>(entity)

  const requestQuery = computed<Record<string, unknown>>(() => ({
    page: pageCurrent.value,
    limit: recordListOptions.limit,
    ...(filters?.value ? filters.value : {}),
  }))

  const { data, error, status, refresh } = await useLazyAsyncData<
    IRecordResponseList<T>
  >(
    options?.key ||
      `${entity}-list-page-${pageCurrent.value}-${route.fullPath}`,
    () =>
      $request<IRecordResponseList<T>>(`/api/${entity}/list`, {
        query: requestQuery.value,
      }),
  )
  const pending = computed<boolean>(() => status.value === 'pending')
  const initializing = computed<boolean>(
    () => !status.value || (pending.value && !data.value),
  )

  const items = computed<T[] | undefined>(() => data.value?.items)
  const pagination = computed<IPagination | undefined>(
    () => data.value?.meta.pagination,
  )

  const reset = (): void => {
    pageCurrent.value = 1
    refresh()
  }

  watch(
    () => filters?.value,
    () => refresh(),
    { deep: true },
  )

  watch(pageCurrent, () => refresh())

  return {
    data,
    items,
    pagination,
    status,
    pending,
    error,
    initializing,
    pageCurrent,
    target,
    create,
    refresh,
    reset,
    setTarget,
    deleteConfirm,
  }
}

export const useRecordListInfinite = async <T>(
  entity: string,
  options: Partial<IRecordListOptions> = recordListOptionsDefaults,
  filters?: Ref<Record<string, unknown>> | ComputedRef<Record<string, unknown>>,
) => {
  const route = useRoute()

  const recordListOptions: IRecordListOptions = {
    ...recordListOptionsDefaults,
    ...options,
  }

  const recordListContainer = ref<HTMLElement | null>(null)
  const recordListPages = ref<IRecordResponseList<T>[]>([]) as Ref<
    IRecordResponseList<T>[]
  >
  const pageCurrent = ref<number>(1)

  const { target, setTarget, create, deleteConfirm } = useRecordBase<T>(entity)

  const requestQuery = computed<Record<string, unknown>>(() => ({
    ...(filters?.value ? filters.value : {}),
    page: pageCurrent.value,
    limit: recordListOptions.limit,
  }))

  // Data fetching
  const { data, status, error, refresh } = await useLazyAsyncData<
    IRecordResponseList<T>
  >(
    options?.key ||
      `${entity}-list-page-${pageCurrent.value}-${route.fullPath}`,
    () =>
      $request<IRecordResponseList<T>>(`/api/${entity}/list`, {
        query: requestQuery.value,
      }),
  )

  // Statuses
  const initializing = computed<boolean>(
    () => !status.value || (pending.value && !data.value),
  )
  const pending = computed<boolean>(() => status.value === 'pending')

  // Data items
  const items = computed<T[]>(
    () => recordListPages.value.flatMap((pageData) => pageData.items) as T[],
  )

  // Pagination
  const pagination = computed<IPagination | undefined>(
    () =>
      recordListPages.value.find(
        (pageItem) => pageItem.meta.pagination.page === pageCurrent.value,
      )?.meta.pagination,
  )
  const recordListPageLast = computed<IRecordResponseList<T> | undefined>(
    () => recordListPages.value[recordListPages.value.length - 1],
  )
  const hasPageNext = computed<boolean>(
    () =>
      !!recordListPageLast.value?.meta.pagination &&
      recordListPageLast.value.meta.pagination.page *
        recordListPageLast.value.meta.pagination.perPage <
        recordListPageLast.value.meta.pagination.total,
  )

  const reset = (): void => {
    pageCurrent.value = 1
    refresh()
  }

  const { arrivedState } = useScroll(recordListContainer, {
    offset: { bottom: recordListOptions.scrollOffset },
  })
  const { bottom: isRecordListBottomArrived } = toRefs(arrivedState)

  watch(isRecordListBottomArrived, (value) => {
    if (!pending.value && value && hasPageNext.value) {
      pageCurrent.value++
    }
  })

  watch(
    () => filters?.value,
    () => {
      pageCurrent.value = 1
      recordListPages.value = []
      refresh()
    },
    { deep: true },
  )

  watch(pageCurrent, () => refresh())

  watch(
    data,
    (value) => {
      if (value) {
        const pageCheck = recordListPages.value.find(
          (item) => item.meta.pagination.page === pageCurrent.value,
        )

        if (!pageCheck) {
          recordListPages.value.push(value)
        }
      }
    },
    { deep: true },
  )

  return {
    data,
    items,
    pagination,
    pending,
    error,
    initializing,
    pageCurrent,
    target,
    container: recordListContainer,
    create,
    refresh,
    reset,
    setTarget,
    deleteConfirm,
  }
}

export const useRecordStyle = <T>(
  record: MaybeRef<T | undefined> | ComputedRef<T | undefined>,
) => {
  const recordItem = toRef(record)

  const statusDesign = computed<TStyleDesign | undefined>(() => {
    if (
      recordItem.value &&
      typeof recordItem.value === 'object' &&
      'status' in recordItem.value
    ) {
      switch (recordItem.value.status) {
        case 'active':
        case 'published':
          return 'success'

        case 'draft':
          return 'info'

        case 'archived':
        case 'unavailable':
          return 'danger'

        case 'closed':
        case 'suspended':
          return 'dark'

        default:
          return undefined
      }
    }

    return undefined
  })

  return {
    statusDesign,
  }
}

export const useRecordPaginationRoute = (pageCurrent: Ref<number>) => {
  const route = useRoute()

  const pageChange = (page = 1): void => {
    pageCurrent.value = page
    navigateTo({
      query: { ...route.query, page: page <= 1 ? undefined : page },
    })
  }

  watch(
    () => route.query.page,
    (value) => {
      pageChange(!!value && !isNaN(+value) ? +value : 1)
    },
    { immediate: true },
  )

  return {
    pageChange,
  }
}
