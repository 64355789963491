import type { NumberFormat } from '@intlify/core-base'
// import type { LocaleObject } from '@nuxtjs/i18n'

// TODO: Revert LocaleObject import back when i18n module imports will be
// resolved.
export type LocaleFile = {
  path: string
  cache?: boolean
}

export interface LocaleObject<T = Locale> extends Record<string, unknown> {
  code: T
  name?: string
  file?: string | LocaleFile
  language?: string
}

export type TLocaleCode = 'gb' | 'nb'

export type TLocaleIso = 'en-GB' | 'nb-NO'

export interface ILocale extends LocaleObject<TLocaleCode> {
  code: TLocaleCode
  language: TLocaleIso
  file: string
  name: string
}

export const AVAILABLE_LOCALES: ILocale[] = [
  {
    code: 'gb',
    language: 'en-GB',
    file: 'en-GB.json',
    name: 'English (GB)',
  },
  {
    code: 'nb',
    language: 'nb-NO',
    file: 'nb-NO.json',
    name: 'Norsk',
  },
]

export const NUMBER_FORMATS: Record<TLocaleCode, NumberFormat> = {
  gb: {
    currency: {
      style: 'currency',
      currency: 'NOK',
      currencyDisplay: 'narrowSymbol',
    },
  },
  nb: {
    currency: {
      style: 'currency',
      currency: 'NOK',
      currencyDisplay: 'narrowSymbol',
    },
  },
}
