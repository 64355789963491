import type { TLocaleCode } from '~~/types/locale'
import { NUMBER_FORMATS } from '~~/types/locale'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: process.env.NUXT_PUBLIC_LOCALE_DEFAULT as TLocaleCode | undefined,
  fallbackLocale: process.env.NUXT_PUBLIC_LOCALE_DEFAULT as
    | TLocaleCode
    | undefined,
  numberFormats: NUMBER_FORMATS,
}))
