<script setup lang="ts">
  import type { TChatReplySuggestion } from '~~/types/chat'

  defineProps<{
    disabled?: boolean
  }>()

  const emit = defineEmits<{
    (e: 'select', value: string): void
    (e: 'cancel'): void
  }>()

  const messageSuggestionListFilters = ref<{
    search: string
  }>({
    search: '',
  })
  const messageSuggestionCurrent = ref<TChatReplySuggestion | undefined>(
    undefined,
  )
  const messageSuggestionSearch = ref<string>('')
  const messageSuggestionSearchDebounced = refDebounced(
    messageSuggestionSearch,
    500,
  )

  const {
    items: messageSuggestionList,
    container: suggestionListContainer,
    pending,
    reset,
  } = await useRecordListInfinite<TChatReplySuggestion>(
    'chats-reply-suggestions',
    {
      refreshOnLoad: false,
    },
    messageSuggestionListFilters,
  )

  const messageSuggestions = computed<TChatReplySuggestion[]>(
    () => messageSuggestionList.value?.filter((message) => !!message) ?? [],
  )

  const messageSuggestionSelect = (value: TChatReplySuggestion) => {
    messageSuggestionCurrent.value =
      messageSuggestionCurrent.value?.id !== value.id ? value : undefined

    if (messageSuggestionCurrent.value?.message) {
      emit('select', messageSuggestionCurrent.value.message)
    }
  }

  watch(messageSuggestionSearchDebounced, () => {
    messageSuggestionListFilters.value.search = messageSuggestionSearch.value
    reset()
  })
</script>

<template>
  <div class="flex h-auto grow flex-col gap-3 overflow-hidden">
    <div>
      <input
        v-model="messageSuggestionSearch"
        type="text"
        class="order-2 w-full truncate rounded border-0 bg-transparent py-2 pl-4 pr-10 outline-none ring-1 ring-inset ring-dark-300 transition-all placeholder:text-sm placeholder:text-dark-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 disabled:opacity-80 dark:ring-dark-400 dark:focus:ring-primary-500"
        :placeholder="$t('chat.filter.suggestion-search.placeholder')"
      />
    </div>

    <div
      ref="suggestionListContainer"
      class="flex h-0 grow flex-col gap-2 overflow-y-auto overflow-x-hidden"
    >
      <template v-if="!messageSuggestions.length">
        <template v-if="pending">
          <SkeletonBase
            v-for="i in 4"
            :key="i"
            class="h-12 w-full animate-pulse"
          />
        </template>

        <div
          v-else
          class="py-10 text-center"
        >
          {{
            !messageSuggestionListFilters.search
              ? $t('chat.message.message-suggestion-list-empty')
              : $t('chat.message.message-suggestion-list-empty-filtered')
          }}
        </div>
      </template>

      <div
        v-for="(suggestion, index) in messageSuggestions"
        :key="index"
        class="relative rounded-md border-2 px-2 py-2 hover:border-primary-500 dark:border-dark-400 dark:hover:border-primary-500"
        :class="{
          'border-primary-500 bg-primary-500 text-white':
            messageSuggestionCurrent?.id === suggestion.id,
        }"
      >
        <details class="[&_p]:open:border-dark-100 [&_svg]:open:rotate-90">
          <summary class="flex list-none items-center justify-between gap-5">
            <div class="flex grow cursor-pointer items-center gap-1">
              <SvgImage
                symbol="icon-chevron-right"
                class="h-4 w-4 rotate-0 transform transition-all"
              />

              <TextHighlight
                :context="suggestion.title"
                :query="messageSuggestionSearch"
              />
            </div>
          </summary>

          <p
            class="mb-1 mt-2 border-t border-dark-50/30 pt-2 dark:border-dark-400"
          >
            <TextHighlight
              :context="suggestion.message"
              :query="messageSuggestionSearch"
            />
          </p>
        </details>

        <div
          class="group absolute right-1 top-1.5 h-8 w-8 cursor-pointer p-2"
          @click="messageSuggestionSelect(suggestion)"
        >
          <div
            class="h-full w-full rounded-full p-0.5 ring-2 transition-all"
            :class="{
              'text-white ring-white group-hover:opacity-80':
                messageSuggestionCurrent?.id === suggestion.id,
              'text-transparent ring-dark-400/70 group-hover:ring-primary-500':
                messageSuggestionCurrent?.id !== suggestion.id,
            }"
          >
            <SvgImage
              symbol="icon-check-default"
              stroke-width="4"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-auto flex items-center">
      <button
        type="button"
        class="relative flex items-center gap-1 rounded-s-md border-y border-s border-primary-500 bg-transparent p-2 text-sm transition-all hover:border-dark-300 hover:bg-dark-300 dark:bg-transparent dark:hover:border-dark-500 dark:hover:bg-dark-500"
        @click.prevent="emit('cancel')"
      >
        <SvgImage
          symbol="icon-x-mark"
          class="size-4"
        />

        <span class="px-1 font-semibold leading-none">{{
          $t('global.label.cancel')
        }}</span>
      </button>

      <button
        type="button"
        :disabled="!messageSuggestionCurrent"
        class="relative flex items-center gap-1 rounded-e-md bg-primary-500 p-2 text-sm font-semibold text-white transition-all hover:bg-primary-400 disabled:opacity-60 disabled:hover:bg-primary-500"
        :title="$t('chat.label.suggest')"
        @click.prevent="
          messageSuggestionCurrent &&
          emit('select', messageSuggestionCurrent.message)
        "
      >
        <span class="px-1 font-semibold leading-none">{{
          $t('global.label.select')
        }}</span>

        <SvgImage
          symbol="icon-light-bulb-solid"
          class="size-5 shrink-0"
        />
      </button>
    </div>
  </div>
</template>
