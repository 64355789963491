export const RECORD_LIST_ITEMS_PER_PAGE = 12

export interface IRecord {
  [field: string]: unknown
}

type TSortableValue = string | number | Date

type TSortable<T> = {
  [K in keyof T]: T[K] extends TSortableValue ? K : never
}[keyof T]

export interface IRecordSorting<T extends object> {
  field?: TSortable<T>
  direction?: 'asc' | 'desc'
}

export interface IRecordTableHeader<T extends object = object> {
  field: keyof T | string
  label?: string
  sortable?: boolean | TSortable<T>
}

export interface IRecordTableSkeleton {
  columns: number
  rows: number
}

export interface IRecordResponse<T, M extends IRecord | undefined = undefined> {
  item: T
  meta?: M
}

export interface IPagination {
  page: number
  perPage: number
  total: number
}

export const PAGINATION_DEFAULT: IPagination = {
  page: 1,
  perPage: RECORD_LIST_ITEMS_PER_PAGE,
  total: 0,
}

export interface IRecordListMeta extends IRecord {
  pagination: IPagination
}

export interface IRecordResponseListBase<
  T,
  M extends IRecordListMeta = IRecordListMeta,
> {
  items: T[]
  meta?: M
}

export interface IRecordResponseList<
  T,
  M extends IRecordListMeta = IRecordListMeta,
> extends IRecordResponseListBase<T, M> {
  meta: M
}

export interface IRecordTarget<T, A = string> {
  item?: T
  action?: A
  pending?: boolean
}

export type PartialDeep<T> = T extends object
  ? {
      [P in keyof T]?: PartialDeep<T[P]>
    }
  : T

export interface IRecordListOptions {
  refreshOnLoad: boolean
  paginateByScroll: boolean
  scrollOffset: number
  limit: number
}
